import Menu from "@/components/Cliente/Menu/Menu.vue";
import Rodape from "@/components/Cliente/Rodape/Rodape.vue";
import Especialista from "@/components/Cliente/Especialista/Especialista.vue";
import BarraPesquisa from "@/components/Cliente/BarraPesquisa/BarraPesquisa.vue";

export default {
    components: {
        Menu,
        Rodape,
        Especialista,
        BarraPesquisa
    },
    data() {
        return {};
    },
    methods: {
        atualizarNome() {
            this.$refs.refMenu.atualizarNome();
            this.$refs.refEspecialista.atualizarLogin();
        }
    }
};
