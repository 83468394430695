export default {
    data() {
        return {
            pesquisa: "",
            bkpPesquisa: "x",
            rolagemTela: false
        };
    },
    mounted() {
        window.addEventListener("scroll", this.esconderBarraPesquisa);
    },
    methods: {
        pesquisar() {
            if (
                this.pesquisa.trim() != "" &&
                this.pesquisa != this.bkpPesquisa
            ) {
                this.bkpPesquisa = this.pesquisa;
                this.$router.push({
                    name: "pesquisa",
                    query: { pesquisa: this.pesquisa }
                });
            }
        },

        esconderBarraPesquisa() {
            this.rolagemTela = window.scrollY > 50 ? "flex" : "none";
        }
    }
};
