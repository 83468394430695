import { mask } from "vue-the-mask";
import axios from "axios";
import { required, minLength, email } from "vuelidate/lib/validators";

export default {
    directives: { mask },
    name: "Especialista",
    data() {
        return {
            dialog: false,
            valid: true,
            token: null,
            blLogado: false,
            blSalvando: false,
            blEnviado: false,
            obForm: {
                campo_nome: "",
                campo_email: "",
                campo_telefone: "",
                campo_cidade: "Curitiba",
                campo_uf: "PR",
                campo_tema: "4",
                campo_assunto: "Imposto+de+Renda+-+IRPF",
                campo_duvida: "",
                campo_teste: "0",
                campo_area: "2",
                usuario_id: "",
                sigilosa: "0",
                intQtdAnexos: "0"
            }
        };
    },
    validations: {
        obForm: {
            campo_nome: {
                required
            },
            campo_email: {
                required,
                email
            },
            campo_telefone: {
                required,
                minLength: minLength(14)
            },
            campo_duvida: {
                required
            }
        }
    },
    created() {
        this.obterToken();

        if (localStorage.getItem("token")) {
            this.blLogado = true;
        }
    },
    methods: {
        atualizarLogin() {
            this.blLogado = true;
            this.obterToken();
        },
        obterToken() {
            if (localStorage.getItem("token")) {
                this.$root.$api
                    .get("especialista/obterToken")
                    .then(response => {
                        this.token = response.retorno.token;
                        this.obForm.usuario_id = response.retorno.id_usuario;
                    });
            }
        },
        mensagemErro(strCampo) {
            return this.$utils.handleErrors(strCampo, this);
        },
        submit() {
            this.$v.obForm.$touch();

            if (!this.$v.obForm.$invalid) {
                this.blSalvando = true;

                axios
                    .post(
                        "https://operacional.econeteditora.com.br/Services/ConsultoriaIntegracao/NovaConsulta?token=" +
                            this.token,
                        { postData: this.obForm },
                        {
                            headers: {
                                "Content-Type":
                                    "application/x-www-form-urlencoded"
                            }
                        }
                    )
                    .then(response => {
                        this.blSalvando = false;

                        this.blEnviado = true;

                        this.obForm.campo_nome = "";
                        this.obForm.campo_email = "";
                        this.obForm.campo_telefone = "";
                        this.obForm.campo_duvida = "";

                        this.$v.obForm.$reset();
                    });
            }
        }
    }
};
