export default {
    data() {
        return {
            pesquisa: "",
            bkpPesquisa: "x",
            arAssuntos: [],
            strBaseUrl: process.env.VUE_APP_ROOT_API + "imagem/",
            strRoute: "",
            key: 0
        };
    },

    created() {
        this.atualizarNome();
        this.listarAssuntos();
    },

    methods: {
        atualizarNome() {
            let strNomeUsuario = localStorage.getItem("nome_usuario") || "";
            let arNomes = strNomeUsuario.split(" ");

            if (strNomeUsuario.indexOf(" - ") != -1) {
                strNomeUsuario = arNomes.slice(0, 3).join(" ");
            } else {
                strNomeUsuario = arNomes.slice(0, 2).join(" ");
            }

            this.strNomeUsuario = strNomeUsuario;

            this.key = new Date().getTime();
        },

        listarAssuntos() {
            this.$root.$api.get("assunto/listar").then(response => {
                this.arAssuntos = response.retorno;
                //console.log("arAssunto", this.arAssuntos);
            });
        },

        divideListaAssuntos(temas) {
            if (temas.length <= 6) {
                return [temas];
            }

            var res = [];
            let intDivisor = temas.length > 12 ? 3 : 2;
            let intTamanhoAnterior = 0;
            do {
                if (intDivisor > 1) {
                    var intQuantidade = Math.ceil(
                        (temas.length - intTamanhoAnterior) / intDivisor
                    );
                } else {
                    var intQuantidade = temas.length - intTamanhoAnterior;
                }

                res.push(
                    temas.slice(
                        intTamanhoAnterior,
                        intQuantidade + intTamanhoAnterior
                    )
                );
                intTamanhoAnterior += intQuantidade;
                intDivisor--;
            } while (intDivisor > 0);

            //console.log(res);
            return res;
        },

        pesquisar() {
            if (
                this.pesquisa.trim() != "" &&
                this.pesquisa != this.bkpPesquisa
            ) {
                this.bkpPesquisa = this.pesquisa;
                this.$router.push({
                    name: "pesquisa",
                    query: { pesquisa: this.pesquisa }
                });
            }
        }
    }
};
